import GoogleIcon from '@/components/icons/google-icon';
import Loading01Icon from '@/components/icons/loading-01';
import { trackLogin, trackSignUp } from '@/utils/tracking';
import { useGoogleAuthLogin } from '@/webservices/hooks/auth/useGoogleLogin';
import { useGoogleLogin } from '@react-oauth/google';
import { useState } from 'react';

export default function GoogleAuthButton({
	mode = 'continue',
	planSearchParam,
	tokenSearchParam,
}: {
	mode: 'sign-in' | 'continue' | 'sign-up';
	planSearchParam: string | string[] | undefined;
	tokenSearchParam: string | string[] | undefined;
}) {
	const { googleLoginTenant, googleLogin } = useGoogleAuthLogin();
	const [isLoading, setIsLoading] = useState(false);

	const login = useGoogleLogin({
		flow: 'auth-code',
		onSuccess: (response) => {
			setIsLoading(true);

			if (tokenSearchParam) {
				googleLoginTenant(response.code, planSearchParam, tokenSearchParam as string).catch(
					(err: unknown) => {
						console.error(err);
					},
				);
			} else {
				googleLogin(response.code, planSearchParam).catch((err: unknown) => {
					console.error(err);
				});
			}

			if (mode === 'sign-up') {
				trackSignUp('google');
			}

			if (mode === 'sign-in') {
				trackLogin('google');
			}

			setIsLoading(false);
		},
	});

	return (
		<button
			className="disabled:text-gray-dark-500-opacity-50 flex w-full items-center justify-center gap-[0.63rem] rounded-2xl bg-white px-[1.25rem] py-[0.88rem] font-roboto text-base font-medium shadow-light transition-colors"
			onClick={() => {
				login();
			}}
			disabled={isLoading}
		>
			{!isLoading ? (
				<>
					<GoogleIcon width={24} height={24} />
					<span className="flex-1 text-center">
						{mode === 'continue' && 'Continuer avec Google'}
						{mode === 'sign-in' && 'Se connecter avec Google'}
						{mode === 'sign-up' && `S'inscrire avec Google`}
					</span>
				</>
			) : (
				<Loading01Icon className="animate-spin" width={24} height={24} />
			)}
		</button>
	);
}
