import { useId } from 'react';

export default function GoogleIcon(props: React.SVGProps<SVGSVGElement>) {
	const id = useId();

	return (
		<svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath={`url(#${id}_clip0_620_5557)`}>
				<mask
					id={`${id}_mask0_620_5557`}
					maskUnits="userSpaceOnUse"
					x="0"
					y="0"
					width="24"
					height="24"
				>
					<path
						d="M23.5568 9.81818H12.375V14.4545H18.8114C18.2114 17.4 15.7023 19.0909 12.375 19.0909C8.44773 19.0909 5.28409 15.9273 5.28409 12C5.28409 8.07273 8.44773 4.90909 12.375 4.90909C14.0659 4.90909 15.5932 5.50909 16.7932 6.49091L20.2841 3C18.1568 1.14545 15.4296 -3.8147e-06 12.375 -3.8147e-06C5.72046 -3.8147e-06 0.375 5.34545 0.375 12C0.375 18.6545 5.72046 24 12.375 24C18.375 24 23.8296 19.6364 23.8296 12C23.8296 11.2909 23.7205 10.5273 23.5568 9.81818Z"
						fill="white"
					/>
				</mask>
				<g mask={`url(#${id}_mask0_620_5557)`}>
					<path d="M-0.71582 19.0909V4.90909L8.55691 12L-0.71582 19.0909Z" fill="#FBBC05" />
				</g>
				<mask
					id={`${id}_mask1_620_5557`}
					maskUnits="userSpaceOnUse"
					x="0"
					y="0"
					width="24"
					height="24"
				>
					<path
						d="M23.5568 9.81818H12.375V14.4545H18.8114C18.2114 17.4 15.7023 19.0909 12.375 19.0909C8.44773 19.0909 5.28409 15.9273 5.28409 12C5.28409 8.07273 8.44773 4.90909 12.375 4.90909C14.0659 4.90909 15.5932 5.50909 16.7932 6.49091L20.2841 3C18.1568 1.14545 15.4296 -3.8147e-06 12.375 -3.8147e-06C5.72046 -3.8147e-06 0.375 5.34545 0.375 12C0.375 18.6545 5.72046 24 12.375 24C18.375 24 23.8296 19.6364 23.8296 12C23.8296 11.2909 23.7205 10.5273 23.5568 9.81818Z"
						fill="white"
					/>
				</mask>
				<g mask={`url(#${id}_mask1_620_5557)`}>
					<path
						d="M-0.71582 4.90909L8.55691 12L12.3751 8.67273L25.466 6.54545V-1.09091H-0.71582V4.90909Z"
						fill="#EA4335"
					/>
				</g>
				<mask
					id={`${id}_mask2_620_5557`}
					maskUnits="userSpaceOnUse"
					x="0"
					y="0"
					width="24"
					height="24"
				>
					<path
						d="M23.5568 9.81818H12.375V14.4545H18.8114C18.2114 17.4 15.7023 19.0909 12.375 19.0909C8.44773 19.0909 5.28409 15.9273 5.28409 12C5.28409 8.07273 8.44773 4.90909 12.375 4.90909C14.0659 4.90909 15.5932 5.50909 16.7932 6.49091L20.2841 3C18.1568 1.14545 15.4296 -3.8147e-06 12.375 -3.8147e-06C5.72046 -3.8147e-06 0.375 5.34545 0.375 12C0.375 18.6545 5.72046 24 12.375 24C18.375 24 23.8296 19.6364 23.8296 12C23.8296 11.2909 23.7205 10.5273 23.5568 9.81818Z"
						fill="white"
					/>
				</mask>
				<g mask={`url(#${id}_mask2_620_5557)`}>
					<path
						d="M-0.71582 19.0909L15.6478 6.54545L19.9569 7.09091L25.466 -1.09091V25.0909H-0.71582V19.0909Z"
						fill="#34A853"
					/>
				</g>
				<mask
					id={`${id}_mask3_620_5557`}
					maskUnits="userSpaceOnUse"
					x="0"
					y="0"
					width="24"
					height="24"
				>
					<path
						d="M23.5568 9.81818H12.375V14.4545H18.8114C18.2114 17.4 15.7023 19.0909 12.375 19.0909C8.44773 19.0909 5.28409 15.9273 5.28409 12C5.28409 8.07273 8.44773 4.90909 12.375 4.90909C14.0659 4.90909 15.5932 5.50909 16.7932 6.49091L20.2841 3C18.1568 1.14545 15.4296 -3.8147e-06 12.375 -3.8147e-06C5.72046 -3.8147e-06 0.375 5.34545 0.375 12C0.375 18.6545 5.72046 24 12.375 24C18.375 24 23.8296 19.6364 23.8296 12C23.8296 11.2909 23.7205 10.5273 23.5568 9.81818Z"
						fill="white"
					/>
				</mask>
				<g mask={`url(#${id}_mask3_620_5557)`}>
					<path
						d="M25.4659 25.0909L8.55682 12L6.375 10.3636L25.4659 4.90909V25.0909Z"
						fill="#4285F4"
					/>
				</g>
			</g>
			<defs>
				<clipPath id={`${id}_clip0_620_5557`}>
					<rect width="24" height="24" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}
