import { googleLoginTenantWs, googleLoginWs } from '@/webservices/auth';
import Cookies from 'js-cookie';
import { useRouter } from 'next/navigation';

export const useGoogleAuthLogin = () => {
	const router = useRouter();

	const googleLogin = async (code: string, planSearchParam: string | string[] | undefined) => {
		const response = await googleLoginWs({ code });

		if (response.access_token) {
			const date = new Date();
			date.setDate(date.getDate() + 7);

			Cookies.set('accessToken', JSON.stringify(response.access_token), {
				expires: date,
				secure:
					process.env.NEXT_PUBLIC_VERCEL_ENV !== 'development' &&
					process.env.NEXT_PUBLIC_VERCEL_ENV !== 'local',
				path: '/',
				sameSite:
					process.env.NEXT_PUBLIC_VERCEL_ENV === 'development' ||
					process.env.NEXT_PUBLIC_VERCEL_ENV === 'local'
						? 'Lax'
						: 'None',
			});

			router.push(planSearchParam ? `/app?plan=${planSearchParam as string}` : '/app');
		}

		return response;
	};

	const googleLoginTenant = async (
		code: string,
		planSearchParam: string | string[] | undefined,
		tokenSearchParam: string,
	) => {
		const response = await googleLoginTenantWs({ code, token: tokenSearchParam });

		if (response.access_token) {
			const date = new Date();
			date.setDate(date.getDate() + 7);

			Cookies.set('accessToken', JSON.stringify(response.access_token), {
				expires: date,
				secure:
					process.env.NEXT_PUBLIC_VERCEL_ENV !== 'development' &&
					process.env.NEXT_PUBLIC_VERCEL_ENV !== 'local',
				path: '/',
				sameSite:
					process.env.NEXT_PUBLIC_VERCEL_ENV === 'development' ||
					process.env.NEXT_PUBLIC_VERCEL_ENV === 'local'
						? 'Lax'
						: 'None',
			});

			router.push(planSearchParam ? `/app?plan=${planSearchParam as string}` : '/app');
		}

		return response;
	};

	return { googleLogin, googleLoginTenant };
};
