import { UserInterface } from '@/models/UserInterface';
import { MissingEnvVarError } from '@/webservices/utils/missing-env-var-error';

type SignInPayload = Pick<UserInterface, 'email' | 'password'>;
type SignInTenantPayload = Pick<UserInterface, 'email' | 'password'> & { token: string };
type SignUpPayload = Pick<
	UserInterface,
	'firstname' | 'lastname' | 'email' | 'password' | 'provider'
>;
type SignUpTenantPayload = Pick<
	UserInterface,
	'firstname' | 'lastname' | 'password' | 'provider'
> & {
	token: string;
};

export const signInWs = async (payload: SignInPayload) => {
	if (!process.env.NEXT_PUBLIC_API_URL) {
		throw new MissingEnvVarError('NEXT_PUBLIC_API_URL environment variable is not set');
	}

	return fetch(`${process.env.NEXT_PUBLIC_API_URL}/v1/auth/sign-in`, {
		method: 'POST',
		body: JSON.stringify(payload),
		headers: {
			'Content-Type': 'application/json',
		},
	}).then((res: Response) => {
		if (res.ok) {
			return res.json() as Promise<{ access_token: string }>;
		}

		return Promise.reject(new Error(`${res.status.toString(10)}: ${res.statusText}`));
	});
};

export const signInTenantWs = async (payload: SignInTenantPayload) => {
	if (!process.env.NEXT_PUBLIC_API_URL) {
		throw new MissingEnvVarError('NEXT_PUBLIC_API_URL environment variable is not set');
	}

	return fetch(`${process.env.NEXT_PUBLIC_API_URL}/v1/auth/sign-in/tenant`, {
		method: 'POST',
		body: JSON.stringify(payload),
		headers: {
			'Content-Type': 'application/json',
		},
	}).then((res: Response) => {
		if (res.ok) {
			return res.json() as Promise<{ access_token: string }>;
		}

		return Promise.reject(new Error(`${res.status.toString(10)}: ${res.statusText}`));
	});
};

export const signUpWs = async (payload: SignUpPayload) => {
	if (!process.env.NEXT_PUBLIC_API_URL) {
		throw new MissingEnvVarError('NEXT_PUBLIC_API_URL environment variable is not set');
	}

	return fetch(`${process.env.NEXT_PUBLIC_API_URL}/v1/users/signup`, {
		method: 'POST',
		body: JSON.stringify(payload),
		headers: {
			'Content-Type': 'application/json',
		},
	}).then((res: Response) => {
		if (res.ok) {
			return res.json() as Promise<{ email: string }>;
		}
		return Promise.reject(new Error(`${res.status.toString(10)}: ${res.statusText}`));
	});
};

export const signUpTenantWs = async (payload: SignUpTenantPayload) => {
	if (!process.env.NEXT_PUBLIC_API_URL) {
		throw new MissingEnvVarError('NEXT_PUBLIC_API_URL environment variable is not set');
	}

	return fetch(`${process.env.NEXT_PUBLIC_API_URL}/v1/users/signup/tenant`, {
		method: 'POST',
		body: JSON.stringify(payload),
		headers: {
			'Content-Type': 'application/json',
		},
	}).then((res: Response) => {
		if (res.ok) {
			return res.json() as Promise<{ email: string }>;
		}
		return Promise.reject(new Error(`${res.status.toString(10)}: ${res.statusText}`));
	});
};

export const googleLoginWs = async (payload: { code: string }) => {
	if (!process.env.NEXT_PUBLIC_API_URL) {
		throw new MissingEnvVarError('NEXT_PUBLIC_API_URL environment variable is not set');
	}

	return fetch(`${process.env.NEXT_PUBLIC_API_URL}/v1/auth/google`, {
		method: 'POST',
		body: JSON.stringify(payload),
		headers: {
			'Content-Type': 'application/json',
		},
	}).then((res: Response) => {
		if (res.ok) {
			return res.json() as Promise<{ access_token: string }>;
		}

		return Promise.reject(new Error(`${res.status.toString(10)}: ${res.statusText}`));
	});
};

export const googleLoginTenantWs = async (payload: { code: string; token: string }) => {
	if (!process.env.NEXT_PUBLIC_API_URL) {
		throw new MissingEnvVarError('NEXT_PUBLIC_API_URL environment variable is not set');
	}

	return fetch(`${process.env.NEXT_PUBLIC_API_URL}/v1/auth/google/tenant`, {
		method: 'POST',
		body: JSON.stringify(payload),
		headers: {
			'Content-Type': 'application/json',
		},
	}).then((res: Response) => {
		if (res.ok) {
			return res.json() as Promise<{ access_token: string }>;
		}

		return Promise.reject(new Error(`${res.status.toString(10)}: ${res.statusText}`));
	});
};

export const requestResetPasswordWs = async (body: string) => {
	if (!process.env.NEXT_PUBLIC_API_URL) {
		throw new MissingEnvVarError('NEXT_PUBLIC_API_URL environment variable is not set');
	}

	return fetch(`${process.env.NEXT_PUBLIC_API_URL}/v1/users/password-reset/request`, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Content-Type': 'application/json',
		},
	}).then((res: Response) => {
		if (res.ok) {
			return res;
		}

		return Promise.reject(new Error(`${res.status.toString(10)}: ${res.statusText}`));
	});
};

export const resetPasswordWs = async (payload: { token: string; password: string }) => {
	if (!process.env.NEXT_PUBLIC_API_URL) {
		throw new MissingEnvVarError('NEXT_PUBLIC_API_URL environment variable is not set');
	}

	return fetch(`${process.env.NEXT_PUBLIC_API_URL}/v1/users/password-reset/confirm`, {
		method: 'POST',
		body: JSON.stringify(payload),
		headers: {
			'Content-Type': 'application/json',
		},
	}).then((res: Response) => {
		if (res.ok) {
			return res;
		}
		return Promise.reject(new Error(`${res.status.toString(10)}: ${res.statusText}`));
	});
};
