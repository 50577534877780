'use client';

import GoogleAuthButton from '@/components/commons/google-auth-button';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { memo } from 'react';

function GoogleAuth({
	mode = 'continue',
	planSearchParam,
	tokenSearchParam,
}: {
	mode?: 'sign-in' | 'continue' | 'sign-up';
	planSearchParam: string | string[] | undefined;
	tokenSearchParam?: string | string[] | undefined;
}) {
	const clientId = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID;

	return (
		<>
			{clientId && (
				<GoogleOAuthProvider clientId={clientId}>
					<GoogleAuthButton
						mode={mode}
						planSearchParam={planSearchParam}
						tokenSearchParam={tokenSearchParam}
					/>
				</GoogleOAuthProvider>
			)}
		</>
	);
}

export default memo(GoogleAuth);
