/* eslint-disable @typescript-eslint/no-explicit-any */
import { sendGTMEvent } from '@next/third-parties/google';

function trackEvent(event: string, args: Record<string, any>) {
	if (typeof window.dataLayer !== 'undefined') {
		sendGTMEvent({ event, ...args });
	} else {
		console.info({
			event,
			...args,
		});
	}
}

export const trackSignUp = (method: string) => {
	trackEvent('sign_up', { method });
};

export const trackLogin = (method: string) => {
	trackEvent('login', { method });
};
